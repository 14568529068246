import moment from "moment";

export const getAvailablePreorderDates = (preorderIntervals) => {
    return Object.keys(preorderIntervals.data).filter(i => parseInt(i)).map(i => moment(i, "YYYY-MM-DD"))
}

export const getMinDate = (preorderIntervals) => {
    if (!preorderIntervals) return null;
    const availablePreorderDates = getAvailablePreorderDates(preorderIntervals);
    return new Date(moment.min(availablePreorderDates).format());
}

export const getMaxDate = (preorderIntervals) => {
    if (!preorderIntervals) return null;
    const availablePreorderDates = getAvailablePreorderDates(preorderIntervals);
    return new Date(moment.max(availablePreorderDates).format());
}



const getAvailableHoursForTheDay = (preorderIntervals, dateString) => {
    return Object.keys(preorderIntervals.data?.[dateString] || {}).filter(a => !isNaN(+a)).map(a => +a)
}

const getAvailableMinutesForTheHour = (preorderIntervals, dateString, minHourForADay) => {
    return Object.keys(preorderIntervals.data[dateString]?.[minHourForADay] || {}).map(a => +a)
}

export const getMinTimeOfDay = (dayTimeString, preorderIntervals) => {
    if (!preorderIntervals) return new Date(moment(dayTimeString).set('hour', 0).set('minutes', 0).format());

    const dateString = moment(dayTimeString).format("YYYY-MM-DD")
    const availableHours = getAvailableHoursForTheDay(preorderIntervals, dateString);
    const minHourForADay =  Math.min(...availableHours).toString().padStart(2, "0");
    const availableMinutes = getAvailableMinutesForTheHour(preorderIntervals, dateString, minHourForADay);
    const minMinuteOfTheHour = Math.min(...availableMinutes);

    return new Date(moment(dayTimeString)
        .set('hour', +minHourForADay)
        .set('minutes', minMinuteOfTheHour)
        .format()
    );
}

export const getMaxTimeOfDay = (dayTimeString, preorderIntervals) => {
    if (!preorderIntervals) return new Date(moment(dayTimeString).set('hour', 23).set('minutes', 59).format());

    const dateString = moment(dayTimeString).format("YYYY-MM-DD")
    const availableHours = getAvailableHoursForTheDay(preorderIntervals, dateString);
    const maxHourForADay = Math.max(...availableHours).toString().padStart(2, "0");
    const availableMinutes = getAvailableMinutesForTheHour(preorderIntervals, dateString, maxHourForADay);
    const maxMinuteOfTheHour = Math.max(...availableMinutes);

    return new Date(moment(dayTimeString)
        .set('hour', +maxHourForADay)
        .set('minutes', +maxMinuteOfTheHour)
        .format()
    );
}
