import React from "react";
import {Image} from "../../../../common/image/Image";
import './RollsetPopupProductList.css'


export const RollsetPopupProductList = ({products}) => {
    return (
        <div
            className='rollset-detail-products-wrapper'
        >
            <div>
                {Object.entries(products.toJS())
                    .map(([productType, productsData]) => Object.values(productsData).map(
                        productData => <div className="popup-product-child rollset-detail-item-wrapper"
                                            key={productData.id}>
                            <Image publicImages={productData.publicImages} className='rollset-detail-item-img'/>
                            <div className='rollset-detail-item-description-wrapper'>
                                <p className="popup-product-child-title">{`#${productData.code} ${productData.title}`} <span className='no-wrap'>{productData.count * 8} шт</span></p>
                                <p className="popup-product-child-announce">{productData.announce}</p>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
};
