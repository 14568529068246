import { all, call, put, takeEvery, select } from "redux-saga/effects";

import { api } from "../../../api/api";
import { featureSagaCreator } from "../../../utils/featureSagaCreator";
import {
	FETCH_PREORDER_INTERVALS, RESET_PREORDER_INTERVALS,
} from "./preorderConstants";
import { preorderIntervalLoaded, resetPreorderIntervals } from "./preorderActions";
import { preorderSelectors } from "./preorderSelectors";

export function* resetPreorderIntervalsSaga()  {
	yield put(resetPreorderIntervals());
}

export function* preorderIntervalsSaga() {
	const hasPreorderIntervals = yield select(preorderSelectors.intervals);
	if (!hasPreorderIntervals) {
		const {result} = yield call(api.preorder.intervals);
		// todo add with error handler
		yield put(preorderIntervalLoaded(result));
		setTimeout(() => {
			resetPreorderIntervalsSaga();
		}, result.ttl*1000);
	}
}

export const preorderIntervalSaga = featureSagaCreator({
	workers: function* () {
		yield all([
			takeEvery(FETCH_PREORDER_INTERVALS, preorderIntervalsSaga),
			takeEvery(RESET_PREORDER_INTERVALS, preorderIntervalsSaga),
		])
	},
});
