import { PAYMENT_METHOD } from "../formComponents/PaymentInput";
import {CART_TIME_TYPES} from "../../_core/cartConstants";


export const orderValidation = {
	confirmPrivacyPolicy: (cart = '') => {
		return !cart.customerInfo.isConfirmPrivacyPolicyRules;
	},

	invalidPhone: (cart = '') => {
		return !cart.customerInfo.phone.slice(4).match(/^(?:17|25|29|33|44)(?:[0-9]{7})$/);
	},

	isOurPhone: (cart = '') => {
		return !cart.customerInfo.phone.slice(4).match(/^(?:17|25|29|33|44)(?=(?!7556655))(?=(?!6040000))[0-9]{7}$/);
	},

	emptyName: (cart = '') => {
		return !cart.customerInfo.name;
	},

	emptyAddress: (cart = {},) => {
		return !(cart.shipping.address.street && cart.shipping.address.house);
	},

	emptyPayment: (cart) => {
    return !cart.priceSpecification.paymentType;
  },

  noTimeForPickup: (cart) => {
    return (cart.shipping.shippingType === 'pickup') &&
			(cart.shipping.timeDetails.timeType === CART_TIME_TYPES.runtime) && !cart.shipping.timeDetails.delay;
  },

  noValidPaymentMethod: (cart) => {
			return cart.shipping.noContactOnDelivery &&
				( cart.priceSpecification.paymentType === PAYMENT_METHOD.cash.id ||
					cart.priceSpecification.paymentType === PAYMENT_METHOD.bank_card.id )
  }
};


export const applyOrderValidationRules = (rulesArray, object) => {
  let validationPassed = true;
  let resultErrorMessage = '';
  rulesArray.forEach(([ rule, errorMessage ]) => {
    if (validationPassed && rule(object)) {
      validationPassed = false;
      resultErrorMessage = errorMessage;
    }
  });
  return { validationPassed, errorMessage: resultErrorMessage };
};
