import {OrderedMap} from "immutable";

import {__, toBool} from "../../../utils/utils";
import RollsetModel, {RollsetChildrenMapModel, RollsetInfo} from "../../../models/Products/Rollset/RollsetModel";
import RollsetItemModel from "../../../models/Products/RollsetItem/RollsetItemModel";
import {PRODUCT_TYPES} from "../productBaseConstants";


const rollsetInfoCreator = data => new RollsetInfo({
    calories: +__(data?.flags.calories),
    carbohydrates: +__(data?.flags.carbohydrates),
    fats: +__(data?.flags.fats),
    proteins: +__(data?.flags.proteins),
    price: __(data.price) / 10000,
    priceAvoided: __(data.price_avoided) / 10000,
    amount: __(data.amount),
    freeSticks: +__(data?.flags.free_sticks),
    rollsCount: +__(data?.flags.rolls_count),
    autoAmount: +__(data?.flags.auto_amount),
});

const getProductItemCount = (productItem) => {
  switch(productItem.product_type) {
    case PRODUCT_TYPES.roll.id:
      return productItem.product_size === 'big' ? 1 : 0.5
    default:
      return 1;
  }
}

export const rollsetChildrenProcess = (data = []) => {
    let result = {};

    data.filter(n => n).forEach(item => {
        if (!result[item.id]) {
            result[item.id] = {
              ...item,
              count: 0
            }
        }
      result[item.id].count += getProductItemCount(item);
    })

    return  (new OrderedMap()).withMutations(map => {
        Object.entries(result).forEach(([id, item]) => {
            map.set(id, rollsetChildCreator(item))
        })
    })

};

export const rollsetChildCreator = data => new RollsetItemModel({
    announce: __(data.announce),
    code: __(data.code),
    id: __(data.id),
    position: __(data.position),
    publicImages: data.public_images,
    title: __(data.title),
    productType: __(data.product_type),
    productSize: __(data.product_size),
    count: __(data.count)
});

const rollsetChildrenCreator = data => new RollsetChildrenMapModel({
	  roll: rollsetChildrenProcess(data.roll),
    garnish: rollsetChildrenProcess(data.garnish)
});

export const rollsetCreator = data => new RollsetModel({
    announce: __(data.announce),
    code: __(data.code),
    id: __(data.id),
    position: __(data.position),
    publicImages: data.public_images,
    title: __(data.title),
    isOnTrade: toBool(data.is_on_trade),
    isSuitableForCurrentCart: toBool(data.is_suitable_for_current_cart),
    hasMedium: toBool(data.has_medium),
    info: rollsetInfoCreator(data),
	  children: rollsetChildrenCreator(data.children),
});

export const rollsetsProcess = (data) => {
    return (new OrderedMap()).withMutations(map => {
        Object.entries(data).forEach(([id, item]) => {
            map.set(id, rollsetCreator(item))
        })
    })
};
