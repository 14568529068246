import React from "react";
import { useSelector } from "react-redux";

import { cartSelectors } from "../../../cart/_core/cartSelectors";
import { StickProductQuantity } from "./StickProductQuantity";
import { WidthRatioImageContainer } from "../../../common/ratioContainers/WidthRatioImageContainer";
import { titleFormatter } from "../../../../utils/titleFormatter";
import {GarnishProductPrice} from "../GarnishProductPrice";


export const StickListItem = ({stick}) => {
  const cartItem = useSelector(state => cartSelectors.cartItem(state, stick));
  const freeStickCount = useSelector(cartSelectors.additionalData).freeItemsRemaining.get('stick');
  const productInCart = cartItem.count ? 'incart' : 'none';

  return (
      <li className="secondary-goods__list-item__stick stick-item" data-item={productInCart}>
        <div className="secondary-goods__item">
          <h3 className="secondary-goods__title">
            <div>
                <span>{titleFormatter(stick.title)}</span>
              {
                !!freeStickCount && <span className="secondary-goods__free">{freeStickCount}</span>
              }
            </div>
          </h3>

					<GarnishProductPrice garnish={stick} freeGarnishesCount={freeStickCount} />

          <WidthRatioImageContainer
              ratio={1860 / 200}
              publicImages={stick.publicImages}
              type="main_fullwidth"
              className="secondary-goods__picture"/>

          <StickProductQuantity product={stick}/>
        </div>
      </li>
  );
};
